import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Team from "../components/Common/Team";
import FunFactsArea from "../components/Common/FunFactsArea";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import FeedbackStyleThree from '../components/Common/FeedbackStyleThree';
import Partner from '../components/MachineLearning/Partner';

import AboutImg from "../assets/images/agency-image/agency-about-img.jpg";

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape3 from "../assets/images/shape3.svg";
import Shape4 from "../assets/images/shape4.svg";

const GizlilikPolitikasi = () => (
    <Layout>
        <SEO title="Gizlilik Politikasi" /> 

        <Navbar />

        <PageBanner pageTitle="Pixel Yazılım" />

        <div className="agency-about-area ptb-80">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-img">
                            <img src={AboutImg} alt="image" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-content">
                        <h2>GİZLİLİK SÖZLEŞMESİ </h2>
                        
1-	TARAFLAR
İşbu sözleşme Pixel Yazılım firma yetkilisi ile XXXX kişi arasında aşağıdaki koşullarda yapılmıştır.
<br></br>
2-	SÖZLEŞMENİN KONUSU
Sözleşmenin konusu, YYYY projesi veya buluş bildirimi kapsamında Pixel Yazılımın yürüttüğü çalışmalar ile ilgili olarak, kendisine verilen, açıklanan gizlilik içerdiği açıkça belirtilen bilgi ve belgenin onayı alınmadıkça herhangi bir 3. gerçek ve/veya tüzel kişiye açıklanmamasını temin edecek olan gizliliğin sınırlarının ve koşullarının belirlenmesidir. 
<br></br>
3-	(PROJE ADI/BULUŞ ADI) PROJE / BULUŞ TANIMI
Proje veya içerikten Pixel Yazılım ile görüşülecek olup site üzerindeki mesajlar bilgi ve belgeler proje kapsamında dahildir.
<br></br>
 GİZLİ BİLGİNİN TANIMI 
Sözleşmede tanımlanan YYYY projesi için  yapılan görüşme esnasında XXXXXX tarafından  Pixel Yazılım’ a açıklanan iş geliştirme projesi ile ilgili fikir, proje, buluş, iş, metod, ilerleme ve patent, telif hakkı, marka, ticari sır yada diğer yasal korunmaya konu olan yada olmayan her türlü yenilik ve çalışma esnasında öğrenilecek yazılı veya sözlü tüm ticari, mali, teknik bilgiler ve konuşma bilgileri sır olarak kabul edilir.


<br></br>
<br></br>
4-	TARAFLARIN YÜKÜMLÜLÜKLERİ
<br></br>
5.1 XXXXX sözleşme kapsamında gerekli her türlü bilgi ve belgeyi Pixel Yazılım’ a vermeyi taahhüt eder. 
<br></br> 
5.2 İşbu sözleşmede söz konusu edilen iş ile ilgili bilgi, belge, firma                                                               
ismi, ünvanı ve firmalarla ilgili sair bilgi ve belgelerin gizli olduğunu ve bu nedenle, sadece kendisinin ve çalışanlarının işi gereği bilmesi gerektiği kadarını bileceklerini ve bu bilgi ve belgelerin hiçbir şekilde XXXXX‘ in izni olmaksızın 3. gerçek ve/veya tüzel kişi ve kuruluşlara çalışma amaçları dışında açıklanmayacağını kabul ve taahhüt eder.
<br></br> 
5.3 XXXX tarafından Pixel Yazılım’ a temin edilmiş belge ve bilginin, XXXX’ in rızası haricinde sözleşmeye aykırı olarak ifşa edildiğinin XXXXX tarafından öğrenilmesi halinde, Pixel Yazılım bundan dolayı sorumlu olacaktır. Pixel Yazılım bu bilgi ve belgelerin 3. şahıslara iletilmemesi için gerekli her türlü tedbiri almayı taahhüt ettiği gibi, her türlü tedbiri almasına rağmen, bu bilgi ve belgelerin yayılmasına mani olamadığını ve/veya kusuru olmadığını ileri sürerek sorumluluktan KURTULAMAZ. 
<br></br> 
5.4 İş bu sözleşmeye aykırı bir durumun gündeme gelmesi ile XXXX ' in maruz kaldığı her türlü maddi ve/veya manevi zararını tazmin etmeyi kabul ve taahhüt eder.
<br></br> 
5-	SÜRE 
İş bu sözleşmenin konusunu oluşturan gizliliğe riayet yükümlülüğü, Pixel Yazılım ve XXXX arasında YYYY projesi üzerinden projenin tarifi ile başlayıp, bu çalışmanın bitiminden sonra da devam edecektir. 
<br></br> 
6-	UYUŞMAZLIK
İş bu sözleşmeden dolayı ihtilaf vukuunda Konya Mahkemeleri ve İcra Daireleri yetkili olacaktır. Sözleşme tarafların karşılıklı rıza ve muvafakatleriyle __.__.20__ tarihinde iki nüsha olarak imza edilmiştir. 
<br></br>                                                                                 

                            <span className="sub-title">Gizlilik Politikasi</span>
                            <h2>Kullanıcı Verileri Koruma Kanunu </h2>
                            <div className="bar"></div>

                            <p>6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK” veya “Kanun”) kapsamında Merkez Kampüs 2. Cadde 3. Sokak, 06800, Bilkent, Ankara, Türkiye adresinde mukim Birleşmiş Milletler Çocuklara Yardım Fonu (UNICEF) Türkiye Milli Komitesi Derneği (“UNICEF”) olarak “Veri Sorumlusu” sıfatıyla anılıyoruz. Kanun kapsamında, Veri Sorumlusu olarak kişisel verilerinizi elde etmemiz, kaydetmemiz, saklamamız, güncellememiz, sınıflandırmamız, mevzuatın izin verdiği ölçüde üçüncü kişilerle paylaşmamız veya onlara devretmemiz gibi konularda “veri işleme” olarak kabul edilen işlemleri mevzuata uygun şekilde gerçekleştiriyoruz. Bu kapsamda, Kanun’a uygun hareket edebilmemiz amacıyla, aydınlatma yükümlülüğümüz kapsamında, tüm hususları dikkatinize sunuyoruz.</p>

                             </div>
                    </div>
                </div>
            </div>

           
            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>

       
        <Footer />
    </Layout>
)

export default GizlilikPolitikasi;